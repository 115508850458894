<template lang="pug">
.media-swiper-comp
  swiper(
    v-if="list.length > 0"
    :slides-per-view="1"
    :space-between="0",
    @slideChange="onSlideChange")
    swiper-slide(v-for="(item, i) in list")
      .img-container(v-if="!item.thumbnail")
        img.img(v-if="!errorList[i]", :src="item.url", @error="onError(i)")
        img.error-img(v-if="errorList[i]", src="../../assets/yd.png")
      .video-box(v-else, @click="playVideo")
        img.img(v-if="!errorList[i]", :src="item.thumbnail", @error="onError(i)")
        .play-btn
        video.video(
          :ref="setItemRef(i)"
          :src="item.url")
  img.error-img(v-if="list.length === 0", src="../../assets/yd.png")
  .pagenation
    .pagenation-item(:class="{ active: currentI >= i }", v-for="(item, i) in list")
</template>

<script>
import {
  defineComponent, onUnmounted, ref, watch,
} from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

export default defineComponent({
  name: 'MediaSwiper',
  props: {
    list: Array,
    isSwiperState: Boolean,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const videoRefs = ref([])

    const currentI = ref(0)

    const playVideo = () => {
      const video = videoRefs.value[currentI.value]
      video.play()
    }

    const errorList = ref([])
    const onError = (i) => {
      errorList.value[i] = true
    }

    const onSlideChange = (e) => {
      currentI.value = e.activeIndex
      errorList.value[e.activeIndex] = false
    }

    const setItemRef = (index) => {
      const cb = (el) => {
        if (!videoRefs.value[index] && el) {
          videoRefs.value[index] = el
        }
      }
      return cb
    }

    return {
      videoRefs,
      currentI,
      onSlideChange,
      setItemRef,
      playVideo,
      errorList,
      onError,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
@import '~@/styles/swiper.css'

.video-box, .media-swiper-comp
  position relative
  margin-bottom -0.32rem
.media-swiper-comp
  position relative
  margin-bottom 0.3rem
  border-radius 0.16rem
  overflow hidden
  background #F0F2F5
.video-box
  width 100%
.video
  width 0
  height 0
.play-btn
  width 1rem
  height 1rem
  position absolute
  left 50%
  top 50%
  margin-left -0.5rem
  margin-top -0.5rem
  background url('~@/assets/ic_play.png') 50% 50% / 100% 100% no-repeat
.pagenation
  position absolute
  display flex
  width 100%
  z-index 100
  box-sizing border-box
.pagenation-item
  flex 1
  background rgba(42, 43, 46, 0.4)
  border-radius 0.1rem
  &.active
    background #fff
.pagenation-item + .pagenation-item
  margin-left 0.16rem
.video-box, .img-container
  height 5.36rem
.img
  height 100%
.video
  height 100%
  object-fit fill
.mask
  position absolute
  left 0
  top 0
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.4)
.error-tips
  font-size 0.3rem
  color #fff
  position absolute
  bottom 0.8rem
  width 100%
  text-align center
.error-img
  width 1rem
  position absolute
  left 50%
  top 50%
  transform translate(-50%, -50%)
.media-swiper-comp, .video-box, .img-container
  height 1.78rem
.pagenation
  bottom 0.04rem
  padding 0 0.11rem
.pagenation-item
  height 0.02rem
  background: rgba(217, 217, 217, 0.2);
  &.active
    background #D9D9D9
.pagenation-item + .pagenation-item
  margin-left 0.04rem
</style>
<style lang="stylus">
.play-btn.loading
  animation rotate 800ms linear infinite
.swiper
  border-radius 0.16rem
@keyframes rotate
  0% {
    transform: rotate(0)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
</style>
