<template lang="pug">
.order-info-comp(v-if="orderInfo")
  ContentBox
    .title {{ orderInfo.payAmount === 0 ? '领取成功' : '购买成功' }}
    .stamp(:class="{ 'buy-stamp': orderInfo.payAmount > 0 }")
    .info-section
      .info-line
        .info-label 孩子昵称
        .info-value {{childInfo.nickname}} {{childInfo.childUserId}}
      .info-line
        .info-label 学习阶段
        .info-value {{getGradeName(orderInfo.grade)}}
      .info-line
        .info-label 课程信息
        .info-value {{goodsInfo.name}} {{orderInfo.tbversion}}
      .info-line
        .info-label 家长手机
        .info-value(v-if="orderInfo.phone") {{orderInfo.phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}}
        .phone-btn(v-else, @click="toPhonePage")
          .phone-btn-text 补充手机号
      .info-line
        .info-label 订单号
        .info-value {{orderInfo.orderId}}
      .info-line
        .info-label 领取时间
        .info-value {{orderInfo.payTime}}
      .info-line.msg-line
        .tips-title {{orderInfo.buttonMsg}}
        .tips-msg {{orderInfo.tipsMsg}}
        template(v-if="orderInfo.isContactTimeOut")
          img.qr-code(
            :src="orderInfo.serviceQrcode")
          .save-btn(@click="downloadImg") 保存图片
</template>

<script setup>
import {
  defineProps, reactive, watch, ref, defineEmits,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import compareVersions from 'compare-versions'
import useVisibilityRefresh from '@/hooks/useVisibilityRefresh'
import ContentBox from '@/components/parents/ContentBox.vue'
import service from '@/services/parents'
import stageList from '@/consts'

const props = defineProps({
  goodsInfo: Object,
  childInfo: Object,
})

const emit = defineEmits(['complete'])

const orderInfo = ref()

const getDetail = () => {
  service.getOrderDetail({ goodsId: props.goodsInfo.id, childUserId: props.childInfo.childUserId })
    .then((res) => {
      orderInfo.value = res.data
      emit('complete', orderInfo.value)
    })
}

useVisibilityRefresh(() => {
  getDetail()
})

const getGradeName = () => stageList.flat().find((item) => item.id === orderInfo.value.grade)?.name

watch(() => props.goodsInfo, (goodsInfo) => {
  if (!goodsInfo) {
    return
  }
  getDetail()
}, { immediate: true })

const router = useRouter()
const store = useStore()
const toPhonePage = () => {
  store.dispatch('setGoodId', props.goodsInfo.id)
  const { orderId } = orderInfo.value
  const url = `${window.location.href.split('#/')[0]}#/phone?orderId=${orderId}`
  window.ydk.openWebview({
    url,
  })
}

const downloadImg = () => {
  window.ydk.downloadImageToAlbum({
    serverId: orderInfo.value.serviceQrcode,
  }, () => {
    window.ydk.toast({ msg: '保存成功' })
  }, () => {
    const { version } = window.ydk.ni
    const isLower = compareVersions.compare(version, '1.7.4', '<')
    if (isLower) {
      window.ydk.toast({ msg: '请升级APP到最新版本' })
    } else {
      window.ydk.toast({ msg: '保存失败' })
    }
  })
}
</script>

<style lang="stylus" scoped>
.stamp
  background-image url('~@/assets/parents/stamp_success.png')
  &.buy-stamp
    background-image url('~@/assets/parents/stamp_buy.png')
.info-section
  margin-top 0.62rem - 0.24rem
.tips-title
  font-size 0.16rem
  line-height 0.24rem
  color #000
  font-weight 500
.tips-msg
  margin-top 0.05rem
  color #B0ACA8
  font-size 0.12rem
  line-height 0.18rem
.msg-line
  display block
  text-align left
.qr-code
  width 1.4rem
  height @width
  display block
  margin 0.24rem auto 0
.phone-btn
  background #FF333A
  box-shadow 0 0.1rem 0.25rem rgba(255, 51, 58, 0.12)
  border-radius 1rem
  padding 0.08rem 0.12rem
.phone-btn-text
  font-size 0.2rem
  line-height 1
  zoom 0.5
  color #fff
.save-btn
  width 0.88rem
  height 0.38rem
  line-height @height
  background #FF333A
  box-shadow 0 0.1rem 0.25rem rgba(255, 51, 58, 0.12)
  border-radius 1rem
  margin 0.2rem auto -0.04rem
  text-align center
  color #fff
  font-size 0.12rem
  font-weight 500
.info-value
  max-width 2.34rem
</style>
