<template lang="pug">
.stage-select-comp
  .stage-section(v-for="(stages, i) in stageList")
    .stage-name {{ i === 0 ? '小学' : '初中' }}
    .grade-name-section
      .grade-name(v-for="grade in stages",
        :class="{ selected: selectedGrade && selectedGrade.id === grade.id }",
        @click="selectedGrade = grade") {{grade.name}}
  .confirm-button-section
    .confirm-button(
      :class="{ disabled: !selectedGrade }"
      @click="$emit('choose', selectedGrade.id, selectedGrade.name)") 确定
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  selectedId: String,
  stageList: Array,
})

const selectedGrade = ref(props.stageList.flat().find((item) => item.id === props.selectedId))
</script>

<style lang="stylus" scoped>
.stage-section
  padding 0 0.2rem
.stage-name
  font-weight 500
  font-size 0.18rem
  line-height 0.28rem
  color #1f1f1f
  margin-bottom 0.09rem
.grade-name-section
  display flex
  justify-content space-between
  flex-wrap wrap
.grade-name
  background #FAFAFA
  border-radius 1rem
  padding 0.1rem 0
  width 30%
  text-align center
  color #8C8C8C
  font-size 0.14rem
  line-height 0.2rem
  font-weight 500
  margin-bottom 0.18rem
  &.selected
    background #FF333A
    color #fff
.confirm-button-section
  position relative
  padding-left 0.24rem
  padding-right 0.24rem
  &::before
    content ''
    display block
    width 100%
    height 1px
    background #e7e7e7
    transform scaleY(0.5)
    position absolute
    top 0
    left 0
</style>
