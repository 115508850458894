<template lang="pug">
.order-select-comp(@click="isSelectVisible = true")
  | {{goodsInfo?.name}}
Popup(
  v-model:show="isSelectVisible",
  position="bottom",
  round)
  .order-cotent
    .header
      .select-title 请选择课程
      .close-icon(@click="isSelectVisible = false")
    .order-item(v-for="item in goodsList",
      :class="{ selected: item.id === goodsInfo.id }"
      @click="choose(orderI, item)") {{item.name}}
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { Popup } from 'vant'

const props = defineProps({
  goodsInfo: Object,
  goodsList: Array,
})

const emit = defineEmits(['choose'])

const isSelectVisible = ref(false)
const choose = (orderI, goodItem) => {
  isSelectVisible.value = false
  emit('choose', goodItem)
}
</script>

<style lang="stylus" scoped>
.order-select-comp
  position relative
  background linear-gradient(91.73deg, #FFFCF9 0.1%, #FFFFFF 102.07%)
  border-radius 0.16rem
  padding 0.16rem 0.2rem
  z-index 10
  margin 0rem 0.2rem 0.2rem
  color #3C3329
  font-size 0.16rem
  line-height 0.24rem
  font-weight 500
  &::after
    content ''
    display block
    position absolute
    right 0.2rem
    top 50%
    width 0.2rem
    height @width
    margin-top -0.1rem
    background url('~@/assets/parents/ic_pointer.png') 0 0 / 100% 100% no-repeat
    transform-origin 50% 50%
    transform rotate(90deg)
.order-item
  text-align center
  padding 0.2rem
  color #3C3329
  font-size 0.16rem
  font-weight 500
  line-height 0.24rem
  border-top 1px solid #F0F0F0
  &.selected
    color #FF333A
.order-content
  padding-bottom 0.34rem
.header + .order-item
  border none
</style>
