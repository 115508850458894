<template lang="pug">
.detail-section(v-if="state.isReady", ref="container")
  .detail-content
    MediaSwiper(:list="state.list",
      :isSwiperState="!state.isTabFixed")
    .detail-box(:class="{ fixed: state.isTabFixed }")
      .tab-box-container(v-if="state.tabs.length > 0")
        .tab-box
          .tab(v-for="(item, i) in state.tabs",
            :class="{ active: i === state.choosedI}",
            @click="switchTab(i)") {{item}}
      .detail-imgs
        .detail-img-container
          img.detail-img(
            :src="state.descImg",
            v-if="!state.isImgError[0]",
            v-show="state.choosedI === 0",
            @error="state.isImgError[0] = true")
          .detail-container(
            v-if="!state.isImgError[1]",
            v-show="state.choosedI === 1",
            :class="{ complete: state.isClassImgCompleted }")
            img.detail-img(
              :src="state.classImg",
              @error="state.isImgError[1] = true"
              @load="state.isClassImgCompleted = true")
          .error-img-container(v-show="(state.choosedI === 0 && state.isImgError[0]) || (state.choosedI === 1 && state.isImgError[1])")
            img.error-img(src="../../assets/yd.png")
</template>

<script>
import {
  reactive, ref, onUnmounted, nextTick, watch,
} from 'vue'
import service from '@/services/panda'
import MediaSwiper from '@/components/parents/MediaSwiper.vue'

export default {
  name: 'Detail',
  components: {
    MediaSwiper,
  },
  props: {
    selected: Object,
  },
  setup(props) {
    const state = reactive({
      isTabFixed: false,
      list: [],
      choosedI: 0,
      isReady: false,
      descImg: '',
      classImg: '',
      tabs: [],
      isImgError: [],
      isClassImgCompleted: false,
      swiperHeight: 0,
    })

    const container = ref()

    const onScroll = () => {
      if (!container.value) {
        return
      }
      const top = container.value.offsetTop
      const topHeight = top + (208 / 390) * window.innerWidth
      state.swiperHeight = topHeight
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      state.isTabFixed = scrollTop >= topHeight
    }

    const reset = async (noData) => {
      state.choosedI = 0
      state.isTabFixed = false
      state.isReady = false
      state.isImgError = []
      state.isClassImgCompleted = false
      if (!noData) {
        await nextTick()
        state.isReady = true
        await nextTick()
      }
    }
    window.addEventListener('scroll', onScroll)

    const getImgSrc = (src) => {
      if (!/\?/.test(src)) {
        return `${src}?imageView&type=webp`
      }
      return `${src}&type=webp`
    }
    const getDetail = (grade, tbversion, goodsId) => {
      service.getDetail({ grade, tbversion, goodsId })
        .then(async (res) => {
          const { data } = res
          reset(!res.data)
          if (!data) {
            return
          }
          state.descImg = data.descriptionImg ? getImgSrc(data.descriptionImg) : ''
          state.classImg = data.outlineImg ? getImgSrc(data.outlineImg) : ''
          state.tabs = data.outlineImg ? ['课程详情', '课程大纲'] : ['课程详情']
          const list = data.imgCarouselList ? data.imgCarouselList.map((item) => ({ url: getImgSrc(item) })) : []
          if (data.videoCarousel) {
            list.unshift(data.videoCarousel)
          }
          state.list = list
        }).catch(() => {
          reset()
          state.list = []
          state.classImg = ''
          state.descImg = ''
        })
    }

    watch(() => props.selected, () => {
      getDetail(props.selected.grade, props.selected.tbversion, props.selected.goodsId)
    }, { immediate: true })

    const detail = ref()
    const switchTab = (i) => {
      state.choosedI = i
      state.isImgError[i] = false
      if (state.isTabFixed) {
        window.scrollTo(0, state.swiperHeight)
      }
    }

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll)
    })

    return {
      state,
      switchTab,
      detail,
      container,
    }
  },
};
</script>

<style lang="stylus" scoped>
img
  display block
.tab-box
  padding 0.1rem 0
  display flex
  color #BFBFBF
  box-sizing border-box
.tab
  padding-bottom 0.04rem
  &:nth-child(2)
    margin-left 0.14rem
  &.active
    color #3A3A3A
    position relative
    &::after
      content ''
      display block
      width 0.14rem
      height 0.04rem
      border-radius 0.1rem
      background #FF333A
      position absolute
      bottom 0
      left 50%
      transform translateX(-50%)
.detail-box
  box-sizing border-box
.tab-box-container
  position relative
  z-index 1
  background #FAFAFA
.fixed
  padding-top 0.48rem
  .tab-box-container
    position fixed
    background #fff
    width 100%
    padding 4px 0.2rem 0
    left 0
    z-index 100
    top -4px
.detail-imgs
  position relative
  padding-top 0.1rem
.detail-img-container, .detail-container
  width 100%
  min-height 1.78rem
  // border-radius 0.2rem
  // overflow hidden
.error-img
  width 1rem
  position absolute
  left 50%
  top 50%
  transform translate(-50%, -50%)
.detail-section
  border-radius 0.16rem
  margin 0.2rem
.tab-box
  font-size 0.16rem
  font-weight 600
  line-height 0.24rem
.error-img-container
  width 100%
  min-height 1.78rem
  border-radius 0.16rem
  background #F0F2F5
.play-btn
  width 1rem
  height @width
  margin-left -0.5rem
  margin-top -0.5rem
</style>
