<template lang="pug">
.child-info-comp
  ContentBox
    .stamp
    .title 孩子信息
    .tips
      .tips-text 请仔细填写信息, 若信息有误会影响课程安排
    .info-section
      .info-line(v-for="(type, i) in infoOrder",
        @click="choose(type)")
        .info-label {{infos[type].label}}
        .info-placeholder(v-if="!infos[type].value") {{infos[type].placeholder}}
        .info-value(v-if="infos[type].value") {{infos[type].value}}
        .info-pointer
  Popup(
    v-model:show="state.isSelectVisible",
    position="bottom",
    round)
    .header
      .select-title {{infos[state.selectedType].title}}
      .close-icon(@click="state.isSelectVisible = false")
    .content(v-if="state.isSelectVisible")
      ChildSelect(v-if="state.selectedType === 'child'",
        :childrenList="state.childrenList",
        :selected="infos.child.selected",
        @choose="onSelected")
      StageSelect(v-if="state.selectedType === 'stage'",
        :stageList="state.stageList",
        :selectedId="infos.stage.selected",
        @choose="onSelected")
      VersionSelect(v-if="state.selectedType === 'version'",
        :selectedStage="infos.stage.selected",
        :selected="infos.version.selected",
        :campList="state.campList",
        @choose="onSelected")
  .confirm-button-section(v-if="state.selected && state.btnState > -1")
    .price-section(v-if="state.btnState === 1")
      span.price-unit ¥
      | 0
    .confirm-button(
      @click="claim",
      :class="[`state-${state.btnState}`]")
      |{{ btnTexts[state.btnState] }}
VLoading(v-if="state.isClaiming")
</template>

<script setup>
import {
  defineEmits, defineProps, reactive, watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Popup, showDialog } from 'vant'
import ContentBox from '@/components/parents/ContentBox.vue'
import ChildSelect from '@/components/parents/select/ChildSelect.vue'
import StageSelect from '@/components/parents/select/StageSelect.vue'
import VersionSelect from '@/components/parents/select/VersionSelect.vue'
import VLoading from '@/components/parents/VLoading.vue'
import service from '@/services/parents'
import utils from '@/utils'
import stageList from '@/consts'

const route = useRoute()
const router = useRouter()
const store = useStore()

const props = defineProps({
  yid: String,
  orders: Array,
})

const emit = defineEmits(['complete', 'success'])

const infoOrder = reactive(['child', 'stage', 'version'])
const infos = reactive({
  child: {
    label: '孩子昵称',
    value: '',
    placeholder: '请确认已添加孩子并绑定平板设备',
    title: '请确认您的孩子信息',
    selected: '',
  },
  stage: {
    label: '学习阶段',
    value: '',
    placeholder: '请选择',
    title: '请选择学习阶段',
    selected: null,
  },
  version: {
    label: '课程信息',
    value: '',
    placeholder: '请选择',
    title: '请选择课程和教材版本',
    selected: null,
  },
})

const btnTexts = ['等待开营', '免费领取', '已结束', '已购买']
const state = reactive({
  isSelectVisible: false,
  selectedType: '',
  childrenList: [],
  campList: [],
  selected: null,
  isClaiming: false,
  stageList,
  btnState: -1, // -1 不展示，0 置灰未开售，1 可领取，2 已结束，3 已购买
})

const choose = (type) => {
  const isNoChildList = type === 'child' && state.childrenList.length === 0
  const isNoChildSelected = type !== 'child' && !infos.child.selected
  const isNoStageSelected = type === 'version' && !infos.stage.selected && !!infos.child.selected
  if (isNoChildList || isNoChildSelected || isNoStageSelected) {
    if (isNoStageSelected) {
      window.ydk.toast({ msg: '请先选择年级' })
    }
    return
  }
  state.isSelectVisible = true
  state.selectedType = type
}

const onSelected = (selected, value) => {
  state.isSelectVisible = false
  infos[state.selectedType].selected = selected
  infos[state.selectedType].value = value
}

const getCourseBtnState = () => {
  const childOrder = props.orders.find((item) => item.childUserId === infos.child.selected.yid)
  const isClaimed = childOrder && !!childOrder.goodsList.find((item) => item.id === infos.version.selected.camp.goodsId)
  if (isClaimed) {
    state.btnState = 3
    return
  }
  state.btnState = -1
  const { goodsId, grade } = state.selected
  service.getCourseState({
    goodsId,
    grade,
    userId: infos.child.selected.yid,
  }).then((res) => {
    state.btnState = res.data.goodsStatus
  })
}

watch(() => infos.child.selected, (selectedChild) => {
  if (!selectedChild || !selectedChild.log_tag) {
    infos.stage.selected = null
    infos.stage.value = ''
    return
  }
  const selected = selectedChild.log_tag
  infos.stage.value = state.stageList.flat().find((item) => item.id === selected)?.name
  infos.stage.selected = infos.stage.value ? selected : ''
})

watch(() => infos.stage.selected, (selectedStage) => {
  state.selected = null
  infos.version.value = ''
  infos.version.selected = null
  if (!selectedStage) {
    state.campList = []
    return
  }
  service.getCampInfo({ grade: selectedStage })
    .then((res) => {
      state.campList = res.data.courseAdvertising
    })
})

watch(() => infos.version.selected, (selectedVersion) => {
  if (!selectedVersion) {
    return
  }
  state.selected = {
    grade: infos.stage.selected,
    tbversion: selectedVersion.version,
    goodsId: selectedVersion.camp.goodsId,
  }
  getCourseBtnState()
})

watch(() => state.selected, () => {
  emit('complete', state.selected)
})

service.getChildrenList()
  .then((res) => {
    state.childrenList = res.data.children
    if (state.childrenList.length > 0) {
      const selected = state.childrenList[0]
      infos.child.selected = selected
      infos.child.value = `${selected.nickname} ${selected.yid}`
    }
  })

const claim = () => {
  if (state.btnState !== 1) {
    return
  }
  state.isClaiming = true
  service.claimCourse({
    redeemCode: utils.getParameter('code'),
    childUserId: infos.child.selected.yid,
    goodsId: infos.version.selected.camp.goodsId,
    channel: 1,
    grade: infos.stage.selected,
    tbversion: infos.version.selected.version,
  }).then((res) => {
    state.isClaiming = false
    const hasPhone = !!res.data.userPhone
    const { orderId } = res.data
    store.dispatch('setGoodId', infos.version.selected.camp.goodsId)
    store.dispatch('setchildYid', infos.child.selected.yid)
    showDialog({
      title: '领取成功',
      message: res.data.tipsMsg,
      confirmButtonText: hasPhone ? '我知道了' : '立即补充',
      cancelButtonText: '稍后补充',
      showCancelButton: !hasPhone,
    }).then(() => {
      if (hasPhone) {
        emit('success')
      } else {
        const url = `${window.location.href.split('#/')[0]}#/phone?orderId=${orderId}`
        window.ydk.openWebview({
          url,
        })
        emit('success')
      }
    }).catch(() => {
      emit('success')
    })
  }).catch((res) => {
    state.isClaiming = false
    if (typeof res === 'object' && res.msg) {
      showDialog({
        title: '领取失败',
        message: res.msg,
        confirmButtonText: '我知道了',
      })
    } else {
      showDialog({
        title: '领取失败',
        message: '领取失败, 请重新领取',
        confirmButtonText: '重新领取',
        showCancelButton: true,
      }).then(() => {
        claim()
      })
    }
  })
}
</script>

<style lang="stylus" scoped>
.tips
  padding-right 0.68rem
  height 0.46rem + 0.18rem
  padding-top 0.03rem
.tips-text
  font-size 0.24rem
  zoom 0.5
  line-height 1
.info-pointer
  width 0.16rem
  height @width
  background url('~@/assets/parents/ic_pointer.png') 0 0 / 100% 100% no-repeat
  margin-left 0.07rem
.info-placeholder
  font-weight normal
.confirm-button-section
  display flex
  position fixed
  bottom 0
  align-content center
  background #fff
  width 3.9rem
  box-sizing border-box
  z-index 100
.price-section
  width 0.82rem
  text-align left
  font-family: 'HarmonyOS Sans SC';
  font-weight: 700;
  font-size 0.4rem
  color #FF333A
  line-height 0.5rem
.price-unit
  font-size 0.14rem
  font-weight 500
  margin-right 0.06rem
  vertical-align 8%
.confirm-button
  flex 1
</style>
<style lang="stylus">
.confirm-button-section
  padding 0.2rem 0.2rem 0.54rem
.van-dialog
  border-radius 0.08rem
  background rgba(255, 255, 255, 0.96)
.van-dialog__header
  padding-top 0.3rem
  font-size 0.18rem
  line-height 0.28rem
  color #1f1f1f
  font-weight 500
  margin-bottom 0.05rem
.van-dialog__message--has-title
  padding-top 0
  padding-bottom 0.3rem
  font-size 0.14rem
  font-weight normal
  line-height 0.2rem
  color #8c8c8c
.van-button
  font-size 0.16rem
  font-weight 500
  background transparent
.van-dialog__confirm, .van-dialog__confirm:active
  color #FF333A
.van-hairline--top:after, .van-hairline--left:after
  border-color #D9D9D9
.confirm-button
  &.state-2
    background #F5F5F5
    color #BFBFBF
  &.state-0, &.state-3
    opacity 0.5
.content
  max-height 4.94rem
  overflow auto
</style>
