<template lang="pug">
.child-select-comp
  .child-line(v-for="child in childrenList",
    :class="{ selected: selected && child.yid === selected.yid }",
    @click="$emit('choose', child, `${child.nickname} ${child.yid}`)")
    |{{child.nickname}}（{{child.yid}}）
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  selected: Object,
  childrenList: Array,
})
</script>

<style lang="stylus" scoped>
.child-select-comp
  padding-bottom 0.34rem
  margin-top -0.2rem
.child-line
  padding 0.2rem
  color #1f1f1f
  font-weight 500
  font-size 0.16rem
  line-height 0.24rem
  text-align center
  &.selected
    color #FF333A
.child-line:not(:first-child)
  border-top 1px solid #F0F0F0
</style>
