<template lang="pug">
.info-outer-box
  .info-box
    .info-inner-box
      .info-content
        slot
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContentBox',
  props: {
  },
});
</script>

<style lang="stylus" scoped>
.info-outer-box
  filter drop-shadow(0px 2px 62px rgba(0, 0, 0, 0.08))
.info-box
  position relative
  width 3.5rem
  margin 0 auto
  border-radius 0.16rem 0.43rem 0.16rem 0.16rem
  background linear-gradient(127.35deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 53.14%, rgba(255, 255, 255, 0) 100.5%);
  padding 0.06rem
  &:before
    content ''
    position absolute
    top 1px
    right 1px
    left 1px
    bottom 1px
    border-radius 0.15rem 0.42rem 0.15rem 0.15rem
    background linear-gradient(316.66deg, rgba(253, 215, 142, 0.2) -0.71%, rgba(255, 255, 255, 0.2) 14.74%), linear-gradient(147.63deg, rgba(255, 144, 41, 0.2) -1.52%, rgba(255, 255, 255, 0.2) 19.86%), #FFFFFE
.info-inner-box
  position relative
  z-index 1
  box-sizing border-box
  background linear-gradient(180deg, #FFD66D 0%, rgba(255, 231, 178, 0.25) 100%)
  border-radius 0.14rem
  &:before
    content ''
    position absolute
    top 1px
    right 1px
    left 1px
    bottom 1px
    border-radius 0.13rem
    background linear-gradient(316.66deg, rgba(253, 215, 142, 0.2) -0.71%, rgba(255, 255, 255, 0.2) 14.74%), linear-gradient(147.63deg, rgba(255, 144, 41, 0.2) -1.52%, rgba(255, 255, 255, 0.2) 19.86%), #FFFFFE
.info-content
  position relative
  z-index 1
  padding 0.14rem 0.14rem 0
  box-sizing border-box
</style>
