<template lang="pug">
.claim-page(:class="{ bottom: state.selected && state.isClaimState}")
  .claim-header-bg
  ChildInfo(v-if="state.isClaimState",
    :orders="orders",
    @complete="state.selected = $event",
    @success="onSuccess")
  template(v-if="!state.isClaimState")
    OrderSelect(v-if="state.isOrderSelectVisible",
      :goodsInfo="state.goodsInfo",
      :goodsList="state.goodsList",
      @choose="onOrderChoose")
    OrderInfo(v-if="state.goodsInfo",
      :goodsInfo="state.goodsInfo",
      @complete="state.selected = $event",
      :childInfo="state.childInfo")
  Detail(v-if="state.selected", :selected="state.selected")
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import 'vant/lib/index.css';
import ChildInfo from '@/components/parents/ChildInfo.vue'
import OrderInfo from '@/components/parents/OrderInfo.vue'
import OrderSelect from '@/components/parents/OrderSelect.vue'
import Detail from '@/components/parents/Detail.vue'
import service from '@/services/parents'

const route = useRoute()
const store = useStore()

if (route.query.childYid) {
  store.dispatch('setchildYid', route.query.childYid)
}

const state = reactive({
  isClaimState: !store.state.childYid,
  selected: null,
  orderList: [],
  goodsInfo: null,
  childInfo: null,
  goodsList: null,
  isOrderSelectVisible: false,
})

const orders = ref([])

const onOrderChoose = (goodsInfo) => {
  state.goodsInfo = goodsInfo
}

const getOrders = () => {
  service.getOrders()
    .then((res) => {
      orders.value = res.data
      if (state.isClaimState) {
        return
      }
      const defaultOrder = res.data.find((item) => item.childUserId === store.state.childYid)
      state.isOrderSelectVisible = defaultOrder.goodsList.length > 1 && route.query.childYid
      const goodI = Math.max(defaultOrder.goodsList.findIndex((item) => item.id === store.state.goodId), 0)
      state.childInfo = {
        childUserId: defaultOrder.childUserId,
        nickname: defaultOrder.nickname,
      }
      state.goodsList = defaultOrder.goodsList
      state.goodsInfo = defaultOrder.goodsList[goodI]
    })
}

const onSuccess = () => {
  state.isClaimState = false
  getOrders()
}

const { ydk } = window
const login = () => {
  ydk.login({
    defer: 3000,
    success: (res) => {
      getOrders()
    },
    fail: () => {
      window.ydk.toast({ msg: '请升级APP到最新版本' })
    },
  })
}

if (ydk.isYD) {
  ydk.isLogin({
    success: (res) => {
      if (!res.isLogin) {
        login()
      } else {
        getOrders()
      }
    },
  })
} else {
  getOrders()
}

</script>

<style lang="stylus">
#app
  width 3.9rem
  margin 0 auto
.claim-page
  color #3C3329
  padding 0.3rem 0
  min-height 100vh
  box-sizing border-box
  background #fafafa
  &.bottom
    padding-bottom 1.24rem
.claim-header-bg
  position absolute
  left 0
  top -1.04rem
  width 100%
  height 3.48rem
  opacity 0.3
  background linear-gradient(180deg, #FFBB32 0%, rgba(255, 255, 255, 0) 111.05%)
.info-line
  padding 0.24rem 0
  display flex
  font-size 0.14rem
  line-height 0.2rem
  color  #B0ACA8
  font-weight 500
  justify-content space-between
  text-align right
  align-items center
  &:not(:first-child)
    border-top 1px solid #E9E8E7
.info-placeholder, .info-value
  flex 1
  margin-left 0.2rem
.info-value
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  color #3c3329
.stamp
  position absolute
  width 0.9rem
  height @width
  right -0.08rem
  top -0.08rem
  background url('~@/assets/parents/stamp_undo.png') 0 0 / contain no-repeat
.title
  font-size 0.22rem
  line-height 0.32rem
  font-weight 700
  margin-bottom 0.04rem
.header
  position relative
  text-align center
  font-size 0.18rem
  line-height 0.2rem
  color #3a3a3a
  font-weight 500
  padding 0.24rem
.close-icon
  width 0.2rem
  height @width
  background url('~@/assets/parents/ic_close.png') 0 0 / contain no-repeat
  position absolute
  right 0.2rem
  top 0.24rem
</style>
