<template lang="pug">
.version-select-comp
  .selected-line(@click="onSelect") {{toSelectCamp ? toSelectCamp.goodsName : '请选择'}}
    template(v-if="selectedCamp")
      | - {{selectedVersion || '请选择'}}
  .select-content
    .camp-list(v-if="!selectedCamp")
      .list-item(v-for="item in campList",
        :class="{ checked: toSelectCamp === item }",
        @click="chooseCamp(item)") {{item.goodsName}}
    .version-list(v-if="selectedCamp")
      .list-item(v-for="item in versions",
        :class="{ checked: selectedVersion === item }"
        @click="selectedVersion = item") {{item}}
  .confirm-button-section
    .confirm-button(
      :class="{ disabled: !selectedCamp ? !toSelectCamp : !selectedVersion }"
      @click="onBtnClick")
      | {{ !selectedCamp ? '下一步' : '确定' }}
</template>

<script setup>
import {
  defineEmits, defineProps, ref, watch,
} from 'vue'
import service from '@/services/parents'

const props = defineProps({
  campList: Array,
  selectedStage: String,
  selected: Object,
})

const emit = defineEmits(['choose'])

const versions = ref([])

const toSelectCamp = ref(null)
const selectedCamp = ref(null)
const selectedVersion = ref('')

if (props.selected) {
  // eslint-disable-next-line vue/no-setup-props-destructure
  toSelectCamp.value = props.selected.camp
  // eslint-disable-next-line vue/no-setup-props-destructure
  selectedCamp.value = props.selected.camp
  // eslint-disable-next-line vue/no-setup-props-destructure
  selectedVersion.value = props.selected.version
}

const chooseCamp = (item) => {
  selectedVersion.value = ''
  toSelectCamp.value = item
}

watch(() => selectedCamp.value, () => {
  versions.value = []
  if (!selectedCamp.value) {
    return
  }
  service.getVersionList({ goodsId: selectedCamp.value.goodsId })
    .then((res) => {
      const { data } = res
      const list = props.selectedStage.includes('pupil') ? data.primaryGradeList : data.juniorGradeList
      versions.value = list.find((item) => item.grade === props.selectedStage)?.versions
    })
}, { immediate: true })

const onBtnClick = () => {
  if (!selectedCamp.value) {
    selectedCamp.value = toSelectCamp.value
    return
  }
  if (!selectedVersion.value) {
    return
  }
  emit('choose', { camp: selectedCamp.value, version: selectedVersion.value }, `${selectedCamp.value.goodsName} ${selectedVersion.value}`)
}

const onSelect = () => {
  toSelectCamp.value = selectedCamp.value
  selectedCamp.value = null
  selectedVersion.value = null
}
</script>

<style lang="stylus" scoped>
.version-select-comp
  height 4.94rem
  display flex
  flex-direction column
.selected-line
  background #fafafa
  padding 0.12rem 0.2rem
  font-weight 500
  font-size 0.14rem
  line-height 0.2rem
  color #1f1f1f
.list-item
  position relative
  padding 0.24rem 0.2rem
  font-weight 500
  font-size 0.16rem
  line-height 0.24rem
  color #3a3a3a
.list-item:not(:first-child):before
  content ''
  display block
  height 1px
  width 100%
  transform scaleY(0.5)
  background #E7E7E7
  position absolute
  top 0
  left 0
.select-content
  flex 1
  overflow auto
.list-item
  padding-right 0.5rem
  &.checked
    background url('~@/assets/parents/ic_checked.png') 3.5rem  50% / 0.2rem 0.2rem no-repeat
</style>
