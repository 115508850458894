import { onUnmounted } from 'vue'

export default function (callback) {
  function refreshData() {
    if (document.visibilityState === 'visible') {
      callback()
    }
  }

  document.addEventListener('visibilitychange', refreshData)

  onUnmounted(() => {
    document.removeEventListener('visibilitychange', refreshData)
  })
}
